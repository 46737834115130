import React from 'react'
import cx from 'classnames'

import META from '@/meta'
import store from '../state/store.js'
import Footer from '@/footer.js'
import { renderModules } from '@/utls.js'
import MiscCarousel from '@/modules/miscCarousel.js'
import { QueryAbout } from '../utls/queryAbout.js'
import { Projects } from '@/svgs.js'

const updateStates = () => {
  store.hydrate({
    projectNav: !store.state.projectNav
  })()
  const html = document.querySelector('html')
  html.classList.add('opened')
}

const About = ({ transitionStatus, entry, exit }) => {
  const data = QueryAbout()
  return (
    <div className={cx('animate__page', transitionStatus)}>
      <META data={data} url={'about'} />
      <div className='nav__push about' />
      <div className='container--xl ma x p1 projectNavContain aboutSpacer'>
        <div className='f jcs aic work__landing-nav' onClick={updateStates}>
          <Projects /><h4 className='p0 m0 mono h5 caps'>Projects</h4>
        </div>
      </div>
      <div className='aboutContain'>
      <MiscCarousel content={data.carousel} />
      {renderModules(data.modules)}
      </div>
      <Footer />
    </div>
  )
}

export default About
