import React from 'react'
import Helmet from 'react-helmet'

function META({ data, url }) {
  const { metaInfo } = data
  const generalDesc = 'GrandArmy is an award winning, multi-disciplinary creative agency. Since 2008.'

  const title = metaInfo && metaInfo.metaTitle == 'GrandArmy' ? metaInfo.metaTitle : (metaInfo && metaInfo.metaTitle ? metaInfo.metaTitle + ' - GrandArmy' : (data.menuTitle && data.menuTitle ? data.menuTitle + ' - GrandArmy' : (data.portfolioTitle && data.portfolioTitle ? data.portfolioTitle + ' - GrandArmy' : (data.clientName && data.clientName ? data.clientName + ' - GrandArmy' : 'GrandArmy'))));

  const metaDescription = metaInfo && metaInfo.metaDescription ? metaInfo.metaDescription : (data.description && data.description ? data.description : generalDesc);

  const metaKeywords = 'GrandArmy, branding, design, studio, new york city, 2008, a24, strategy';
  const ogImage = metaInfo && metaInfo.openImageUrl ? metaInfo.openImageUrl : 'https://cdn.sanity.io/images/9ielf7cc/production/3a95458532336623d3eaa685f4c2124fcb43849f-1200x600.jpg';
  const twitterImage = metaInfo && metaInfo.twitterImageUrl ? metaInfo.twitterImageUrl : 'https://cdn.sanity.io/images/9ielf7cc/production/3a95458532336623d3eaa685f4c2124fcb43849f-1200x600.jpg';

  const openTitle = metaInfo && metaInfo.metaTitle == 'GrandArmy' ? metaInfo.metaTitle : (metaInfo && metaInfo.title ? metaInfo.title + ' - GrandArmy' : (data.portfolioTitle && data.portfolioTitle ? data.portfolioTitle + ' - GrandArmy' : (data.menuTitle && data.menuTitle ? data.menuTitle + ' - GrandArmy' : (data.clientName && data.clientName ? data.clientName + ' - GrandArmy' : 'GrandArmy'))));

  const openGraphDescription = metaInfo && metaInfo.openGraphDescription ? metaInfo.openGraphDescription : (data.description && data.description ? data.description : generalDesc);

  const twitterTitle = metaInfo && metaInfo.twitterTitle ? metaInfo.twitterTitle + ' - GrandArmy' : (data.portfolioTitle && data.portfolioTitle ? data.portfolioTitle + ' - GrandArmy' : (data.menuTitle && data.menuTitle ? data.menuTitle + ' - GrandArmy' : (data.clientName && data.clientName ? data.clientName + ' - GrandArmy' : 'GrandArmy')));

  const twitterDescription = metaInfo && metaInfo.twitterDescription ? metaInfo.twitterDescription : (data.description && data.description ? data.description : generalDesc);

  return (
    <Helmet title={title}>
    <meta name='twitter:title' content={`${twitterTitle}`} />
    <meta property='og:title' content={`${openTitle}`} />
      <meta name='description' content={metaDescription} />
      <meta property='og:description' content={openGraphDescription} />
      <meta name='twitter:description' content={twitterDescription} />
      <meta name='keywords' content={metaKeywords} />
      <meta property='og:url' content={`https://www.grandarmy.com/${url ? url : ''}`} />
      <meta property='og:image' content={ogImage} />
      <meta property='og:site_name' content='GrandArmy' />
      <meta property='og:type' content='article' />
      <meta name='twitter:site' content='@GrandArmy' />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:image:src' content={twitterImage} />
      <meta name='twitter:url' content={`https://www.grandarmy.com/${url ? url : ''}`} />

        <meta http-equiv='Accept-CH' content='DPR, Width, Viewport-Width' />
        <meta property='og:locale' content='en_US' />
        <meta name='google-site-verification' content='' />
    </Helmet>
  )
}

export default META
