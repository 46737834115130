import { useStaticQuery, graphql } from 'gatsby'

export function QueryAbout () {
  const query = useStaticQuery(
    graphql`
      {
        sanityAbout {
          id
          internal {
            type
            content
            contentDigest
            owner
          }
        }
      }
    `
  )
  return JSON.parse(query.sanityAbout.internal.content)
}
